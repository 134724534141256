/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}




/* Small devices 
(portrait tablets and large phones, 600px and up) @media only screen and (min-width: 600px) {}
*/

@media only screen and (min-width: 600px) {

  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) 
@media only screen and (min-width: 768px) {}
*/
@media only screen and (min-width: 768px) {

  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) {}
*/

/* Extra large devices
* (large laptops and desktops, 1200px and up)
* @media only screen and (min-width: 1200px) {}
*/
