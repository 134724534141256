@import './cover.css' ;
@import './queries.css';
@import './gradients.css';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.bd-placeholder-img {
   font-size: 1.125rem;
   text-anchor: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }

 @media (min-width: 768px) {
   .bd-placeholder-img-lg {
     font-size: 3.5rem;
   }
 }

 .inner.cover {
   min-height: 100vh;
 }

 	#mc_embed_signup {
 	  clear: left;
 	  width: 100%;
   }
   
   p.lead {
     font-size: 1.5rem;
     line-height: normal;
     margin-bottom: 20px;
     color: black;
   }